import {
  Box,
  Button,
  Hidden,
  makeStyles,
  Snackbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import SariskaMediaTransport from "sariska-media-transport";
import { color } from "../../../assets/styles/_color";
import { useHistory } from "react-router-dom";
import { localTrackMutedChanged } from "../../../store/actions/track";
import { addConference } from "../../../store/actions/conference";
import {
  getToken,
  trimSpace,
  detectUpperCaseChar,
  getRandomColor
} from "../../../utils";
import { addThumbnailColor } from "../../../store/actions/color";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import SettingsIcon from '@material-ui/icons/Settings';
import Modal from '@material-ui/core/Modal';
import TextInput from "../../shared/TextInput";
import { setProfile, setMeeting , updateProfile} from "../../../store/actions/profile";
import JoinTrack from "../JoinTrack";
import { addConnection } from "../../../store/actions/connection";
import SnackbarBox from "../../shared/Snackbar";
import { showNotification } from "../../../store/actions/notification";
import { setDisconnected } from "../../../store/actions/layout";
import Logo from "../../shared/Logo";
import DrawerBox from "../../shared/DrawerBox";
import SettingsBox from "../../meeting/Settings";
import FancyButton from "../../shared/FancyButton";
import StyledTooltip from "../../shared/StyledTooltip";
import Icons from "../../shared/iconList";
import permission from '../../../assets/images/shared/permission.png';
import googleSettings from '../../../assets/images/shared/google_settings.png';
import safariSettings from '../../../assets/images/shared/safari_settings.png';

const LobbyRoom = ({ tracks, cameraPermission, audioPermission }) => {
  const history = useHistory();
  const audioTrack =  useSelector((state) => state.localTrack).find(track=>track?.isAudioTrack());
  const videoTrack =  useSelector((state) => state.localTrack).find(track=>track?.isVideoTrack());
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [meetingTitle, setMeetingTitle] = useState("");
  const [name, setName] = useState("");
  const [buttonText, setButtonText] = useState("Start Meeting");
  const [accessDenied, setAccessDenied] = useState(false);
  const profile = useSelector((state) => state.profile);
  const queryParams = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const iAmRecorder = window.location.hash.indexOf("iAmRecorder") >= 0;
  const testMode = window.location.hash.indexOf("testMode") >= 0;
  const notification = useSelector((state) => state.notification);
  const [settingsState, setSettingsState] = React.useState({
    right: false,
  });
  const moderator = useRef(true);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: 'flex-start'
    },
    permissions: {
      display: "flex",
      justifyContent: "left",
      paddingLeft: "0",
      paddingRight: "0",
      marginTop: "3.73vh",
      "& svg": {
        //border: `1px solid ${color.white}`,
        borderRadius: "7.5px",
        color: color.white,
        fontSize: "1.87vw",
        border: "1px solid grey",
        "&:hover": {
          color: color.primaryLight,
          cursor: "pointer",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "1.6rem",
        }
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "10px !important",
        padding: '0 50px',
        width: '250px',
        margin: 'auto'
      }
    },

    joinPermissions: {
      display: "flex",
      justifyContent: "left",
      paddingLeft: "0",
      paddingRight: "0",
      marginTop: "3.73vh",
      //marginBottom: theme.spacing(3),
      "& svg": {
        //border: `1px solid ${color.white}`,
        padding: "12px 30px 12px 0",
        borderRadius: "7.5px",
        color: color.white,
        fontSize: "1.87vw",
        "&:hover": {
          color: color.primaryLight,
          cursor: "pointer",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "1.6rem",
        }
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "10px !important",
        padding: '0 50px',
        width: '250px',
        margin: 'auto'
      }
    },
    disable: {
      background: color.red,
      "&:hover": {
        opacity: "0.8",
        background: `${color.red} !important`,
      },
    },
    textBox: {
      width: "100%",
      //marginBottom: "60px"
    },
    userBox: {
      marginTop: '1vh',
      marginBottom: '1vh',
      [theme.breakpoints.down("sm")]: {
        marginTop: '10px',
        marginBottom: '10px'
      }
    },
    moderatorBox: {
      display: "flex",
      justifyContent: "space-between",
      color: color.lightgray1,
      alignItems: "center",
      padding: "0px 8px 8px",
    },
    action: {
      opacity: .9
    },
    anchor: {
      color: color.black,
      textDecoration: "none",
      padding: theme.spacing(0.5, 5),
      borderRadius: "10px",
      textTransform: "capitalize",
      marginTop: '5.4vh',
      width: '178.69px',
      background: "#FFB448 !important",
      "&:hover": {
        fontWeight: "900",
        background: `linear-gradient(to right, ${color.primaryLight}, ${color.buttonGradient}, ${color.primary})`,
      }
    },
    videoContainer: {
      borderRadius: "4px",
      backgroundColor: "#1E0B39",
      backdropFilter: `blur(48px)`,
      '-webkit-backdrop-filter': 'blur(48px)',
      transition: `background-color .2s ease`,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      zIndex: 1,
      padding: "2.74vw 4.74vw",
      border: `1px solid ${color.whitePointOne}`,
      marginRight: 'auto',
      minHeight: '90vh',
      [theme.breakpoints.down("md")]: {
        padding: "24px 0",
        backgroundColor: videoTrack?.isMuted() ? color.blurEffect : color.lightBlurEffect,
        border: `none`,
        minHeight: 'fit-content',
        borderRadius: "20px 20px 0px 0px",
        marginLeft: 0,
        marginRight: 0,
        width: '350px'
      }
    },
    logoContainer: {},
    header: {
      color: color.white,
      textAlign: "left",
      fontSize: "2.385vw",
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.7rem",
        marginTop: '0',
      }
    },
    headerJoin: {
      color: color.white,
      textAlign: "left",
      fontSize: "2.385vw",
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.7rem",
        marginTop: '0',
      }
    },
    wrapper: {
      margin: "2.3vh 0px 0.5vh 0px",
      position: "relative",
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
        marginBottom: 0,
      }
    },
    buttonSuccess: {
      backgroundColor: color.primary,
      "&:hover": {
        backgroundColor: color.primary,
      },
    },
    buttonProgress: {
      color: color.primary,
      position: "absolute",
      bottom: "4.5vh",
      top: "30px",
      left: "50%",
      marginLeft: -12,
    },
    buttonProgressJoin: {
      color: 'black',
      top: "30px",
      position: "absolute",
      bottom: '4.5vh',
      left: "50%",
      marginLeft: -12,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      width: '600px',
      overflow: 'scroll',
      backgroundColor: theme.palette.background.paper,
      height: '-webkit-fill-available',
      padding: theme.spacing(2, 4, 3),
      border: 'none',
      borderRadius: '5px'
    },
    paper2: {
      width: '600px',
      overflow: 'scroll',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3),
      border: 'none',
      borderRadius: '5px'
    },
    right: {
      textAlign: 'right'
    },
    center: {
      textAlign: 'center'
    },
    greenButton: {
      background: '#27AE60',
      color: 'white',
      padding: '7px 15px',
      borderRadius: '15px',
      border: 'none',
      cursor: 'pointer'
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center'
    },
    purple: {
      color: '#482F80',
      fontWeight: 'bold'
    },
    modalp: {
      color: '#797979',
      fontSize: '14px'
    },
    rightPanel: {
      marginLeft: '10px'
    },
    grey: {
      color: 'grey !important',
      cursor: 'no-drop'
    }
  }));

  const classes = useStyles();

  const handleTitleChange = (e) => {
    setMeetingTitle(trimSpace(e.target.value.toLowerCase()));
  };

  const handleUserNameChange = (e) => {
    setName(e.target.value);
    if (e.target.value.length === 1 ) {
      dispatch(updateProfile({key: "color", value: getRandomColor()}));
    }
    if (!e.target.value) {
      dispatch(updateProfile({key: "color", value: null}));
    }
  };

  const handleSubmit = async () => {
    if (!meetingTitle) {
      dispatch(
        showNotification({
          message: "Meeting Title is required",
          severity: "warning",
          autoHide: true,
        })
      );
      return;
    }

    setLoading(true);
    let avatarColor = profile?.color ?  profile?.color : getRandomColor();
    dispatch(updateProfile({key: "color", value: avatarColor}));

    const token = await getToken(profile, name, avatarColor);
    const connection = new SariskaMediaTransport.JitsiConnection(
      token,
      meetingTitle,
      process.env.REACT_APP_ENV === "development" ? true : false
    );

    connection.addEventListener(
      SariskaMediaTransport.events.connection.CONNECTION_ESTABLISHED,
      () => {
        dispatch(addConnection(connection));
        createConference(connection);
      }
    );

    connection.connect();
  };

  const createConference = async (connection) => {
    // const conference = connection.initJitsiConference({
    //   createVADProcessor: SariskaMediaTransport.effects.createRnnoiseProcessor,
    // });
    console.log('Joined');
    const conference = connection.initJitsiConference();
    tracks.forEach(async track => await conference.addTrack(track));

    conference.addEventListener(
      SariskaMediaTransport.events.conference.CONFERENCE_JOINED,
      () => {
        console.log('CONFERENCE_JOINED')
        setLoading(false);
        dispatch(addConference(conference));
        dispatch(setProfile(conference.getLocalUser()));
        dispatch(setMeeting({ meetingTitle }));
        dispatch(addThumbnailColor({participantId: conference?.myUserId(), color:  profile?.color}));
      }
    );

    conference.addEventListener(
      SariskaMediaTransport.events.conference.USER_ROLE_CHANGED,
      (id, role) => {
        console.log('USER_ROLE_CHANGED', id, role)
        if (conference.isModerator() && !testMode) {
          // conference.enableLobby();
          console.log('moderator');
          history.push(`/${meetingTitle}`);
        } else {
          console.log('Else')
          // conference.enableLobby();
          history.push(`/${meetingTitle}`);
        }
      }
    );

    conference.addEventListener(
      SariskaMediaTransport.events.conference.CONFERENCE_ERROR,
      () => {
        setLoading(false);
      }
    );

    conference.addEventListener(
      SariskaMediaTransport.events.conference.USER_JOINED,
      (id) => {
        dispatch(
          addThumbnailColor({ participantId: id, color: getRandomColor() })
        );
      }
    );

    conference.addEventListener(
      SariskaMediaTransport.events.conference.CONFERENCE_FAILED,
      async (error) => {
        if (
          error === SariskaMediaTransport.errors.conference.MEMBERS_ONLY_ERROR
        ) {
          setButtonText("Asking to join");
          conference.joinLobby(name || conference?.getLocalUser()?.name);
        }

        if (
          error ===
          SariskaMediaTransport.errors.conference.CONFERENCE_ACCESS_DENIED
        ) {
          setAccessDenied(true);
          setButtonText("Join Meeting");
          setLoading(false);
          setTimeout(() => setAccessDenied(false), 2000);
        }
      }
    );
    conference.join();
  };

  const unmuteAudioLocalTrack = async () => {
    await audioTrack?.unmute();
    dispatch(localTrackMutedChanged());
  };

  const muteAudioLocalTrack = async () => {
    await audioTrack?.mute();
    dispatch(localTrackMutedChanged());
  };

  const unmuteVideoLocalTrack = async () => {
    await videoTrack?.unmute();
    dispatch(localTrackMutedChanged());
  };

  const muteVideoLocalTrack = async () => {
    await videoTrack?.mute();
    dispatch(localTrackMutedChanged());
  };

  if (iAmRecorder && !meetingTitle) {
    setName("recorder");
    setMeetingTitle(queryParams.meetingId);
  }

  useEffect(() => {
    if (meetingTitle && (testMode || iAmRecorder)) {
      handleSubmit();
    }
  }, [meetingTitle]);

  useEffect(() => {
    if ((!audioTrack || !videoTrack) && !iAmRecorder ) {
        setLoading(true);
    } else {
        setLoading(false);
    }
  }, [audioTrack, videoTrack]);

  useEffect(() => {
    const usernameFromParams = searchParams.get('username');
    if (usernameFromParams) {
      setName(usernameFromParams.replace(/_/g, " "));
    }
  }, []);


  useEffect(() => {
    if (queryParams.meetingId) {
      setButtonText("Join Meeting");
      setMeetingTitle(queryParams.meetingId);
    }
  }, [profile?.name]);

  const toggleSettingsDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setSettingsState({ ...settingsState, [anchor]: open });
  };

  const settingsList = (anchor) => (
    <Box
      onKeyDown={toggleSettingsDrawer(anchor, false)}
    >
      <SettingsBox onClick={toggleSettingsDrawer("right", false)}/>
    </Box>
  );

  console.log(audioPermission);
  console.log(cameraPermission);

  const [openMoreInfoModal, setOpenMoreInfoModal] = useState(false);
  const [openPermissioModal, setOpenPermissionModal] = useState(false);

  const handleOpenMoreInfoModal = () => {
    setOpenMoreInfoModal(true);
  };

  const handleCloseMoreInfoModal = () => {
    setOpenMoreInfoModal(false);
  };

  const handleClosePermissionModal = () => {
    setOpenPermissionModal(false);
  };

  const handleOpenPermissionModal = () => {
    setOpenMoreInfoModal(false);
    setOpenPermissionModal(true);
  };

  useEffect(() => {
    console.log("audioPermission:", audioPermission);
    console.log("cameraPermission:", cameraPermission);
    setOpenMoreInfoModal(!audioPermission || !cameraPermission);
  }, [audioPermission, cameraPermission]);

  return (
    <Box className={classes.root}>
      <JoinTrack tracks={tracks} name={name} />
      <Box className={classes.videoContainer}>
        <Hidden smDown>
        <Box className={classes.logoContainer}>

        </Box>
        </Hidden>
        <Box>
        {queryParams.meetingId && searchParams.get('meeting_name') ?
          <Typography className={classes.headerJoin}>Join {searchParams.get('meeting_name').replace(/_/g, " ")}</Typography>
          :
          queryParams.meetingId ?
            <Typography className={classes.header}>{searchParams.get('meeting_name')}</Typography>
          :
            <Typography className={classes.header}>Create Meeting</Typography>
        }
        </Box>
        <Box className={!queryParams.meetingId ? classes.permissions : classes.joinPermissions}>
          {audioTrack?.isMuted() ? (
            <div style={{ position: 'relative', padding: "12px 30px 12px 0" }}>
              <StyledTooltip title="Unmute Audio">
                <MicOffIcon onClick={unmuteAudioLocalTrack} />
              </StyledTooltip>
            </div>
          ) : (
            <div style={{ position: 'relative', padding: "12px 30px 12px 0" }}>
              <StyledTooltip title="Mute Audio">
                <MicIcon onClick={muteAudioLocalTrack} className={`${!audioPermission ? classes.grey : ''}`} />
              </StyledTooltip>
              {!audioPermission && (
                <Tooltip title="More info">
                  <i
                    className="google-material-icons"
                    aria-hidden="true"
                    style={{
                      position: 'absolute',
                      top: '2px',
                      fontStyle: 'normal',
                      right: '22px',
                      cursor: 'pointer',
                      backgroundColor: 'rgb(250,123,23)',
                      borderRadius: '50%',
                      color: 'white',
                      fontSize: '14px',
                      width: '20px',
                      height: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={handleOpenMoreInfoModal}
                  >
                    !
                  </i>
                </Tooltip>
              )}
              <Modal
                open={openMoreInfoModal}
                onClose={handleCloseMoreInfoModal}
                className={classes.modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <div className={`${classes.paper2} ${classes.center}`}>
                  <h3 className={classes.purple}>Let people see and hear you in the meeting</h3>
                  <img src={permission} />
                  <p>You’ll need to grant CV Meet permission to use your camera and / or microphone. You can disable these during the meeting if you wish.</p>

                  <div className={classes.right}>
                    <button onClick={handleOpenPermissionModal} className={classes.greenButton}>
                      Next
                    </button>
                  </div>
                </div>
              </Modal>

              <Modal
                open={openPermissioModal}
                onClose={handleClosePermissionModal}
                className={classes.modal}
                aria-labelledby="another-modal-title"
                aria-describedby="another-modal-description"
              >
                <div className={classes.paper}>
                  <h3 className={`${classes.purple} ${classes.center}`}>Let people see and hear you in the meeting</h3>
                  <p>If you don’t see any requests for permission:.</p>
                  <h4 className={classes.purple}>Chrome & Firefox</h4>
                  <div className={classes.flexCenter}>
                    <img src={googleSettings} />
                    <div className={classes.rightPanel}><b>Chrome</b><br /><span className={classes.modalp}>Click the icon beside your search bar to manage this (left).</span></div>
                  </div>
                  <p className={classes.modalp}>You can also visit your Settings > Privacy & Security > Permissions to access your camera and microphone. </p>

                  <h4 className={classes.purple}>Safari</h4>
                  <img src={safariSettings} />
                  <p className={classes.modalp}>If you don’t see any requests for permission, visit Settings > Websites to access your camera and microphone.</p>
                  <div className={classes.right}>
                    <button onClick={handleClosePermissionModal} className={classes.greenButton}>
                      Close
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
          )}
          {videoTrack?.isMuted() ? (
            <div style={{ position: 'relative', padding: "12px 30px 12px 0" }}>
              <StyledTooltip title="Unmute Video">
                <VideocamOffIcon onClick={unmuteVideoLocalTrack} />
              </StyledTooltip>
            </div>
          ) : (
            <div style={{ position: 'relative', padding: "12px 30px 12px 0" }}>
              <StyledTooltip title="Mute Video">
                <VideocamIcon onClick={muteVideoLocalTrack} className={`${!cameraPermission ? classes.grey : ''}`} />
              </StyledTooltip>
              {!cameraPermission && (
                <Tooltip title="More info">
                  <i
                    className="google-material-icons"
                    aria-hidden="true"
                    style={{
                      position: 'absolute',
                      top: '2px',
                      fontStyle: 'normal',
                      right: '22px',
                      cursor: 'pointer',
                      backgroundColor: 'rgb(250,123,23)',
                      borderRadius: '50%',
                      color: 'white',
                      fontSize: '14px',
                      width: '20px',
                      height: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={handleOpenMoreInfoModal}
                  >
                    !
                  </i>
                </Tooltip>
              )}
            </div>
          )}
          <div style={{padding: '12px 30px 12px 0'}}>
            <StyledTooltip title="Settings">
              <SettingsIcon onClick={toggleSettingsDrawer("right", true)} />
            </StyledTooltip>
          </div>
        </Box>
        <Box className={classes.action}>
          <div className={classes.wrapper}>
            <Box className={classes.textBox}>
              {!queryParams.meetingId ? <>
              <TextInput
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSubmit();
                  }
                  // if (e.charCode === 32) {
                  //   dispatch(
                  //     showNotification({
                  //       message: "Space is not allowed",
                  //       severity: "warning",
                  //       autoHide: true,
                  //     })
                  //   );
                  // // } else if (detectUpperCaseChar(e.key)) {
                  // //   dispatch(
                  // //     showNotification({
                  // //       message: "Capital Letter is not allowed",
                  // //       severity: "warning",
                  // //       autoHide: true,
                  // //     })
                  // //   );
                  // }
                }}
                label="Meeting Title"
                width="20vw"
                value={meetingTitle}
                onChange={handleTitleChange}
              />
              </> :
              null}
              <Box className={classes.userBox}>
                <TextInput
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSubmit();
                    }
                  }}
                  label="Username"
                  width="20vw"
                  value={name}
                  onChange={handleUserNameChange}
                />
              </Box>
            </Box>

          </div>
        </Box>
        <Box style={{textAlign: 'center', position: 'relative'}}>
          <FancyButton
              homeButton={true}
              disabled={loading}
              onClick={handleSubmit}
              buttonText={buttonText}
            />
            {loading && (
              <CircularProgress size={24} className={ !queryParams?.meetingId ? classes.buttonProgress : classes.buttonProgressJoin} />
            )}
        </Box>
        <div style={{fontWeight: 'bold', position: 'absolute', bottom: '20px'}}>Powered by CoachVantage</div>
      </Box>
      <DrawerBox
        open={settingsState["right"]}
        onClose={toggleSettingsDrawer("right", false)}
        top="50px"
      >
        {settingsList("right")}
      </DrawerBox>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={2000}
        open={accessDenied}
        message="Conference access denied by moderator"
      />
      <SnackbarBox notification={notification} />
    </Box>
  );
};

export default LobbyRoom;
